//import logo from './images/signature_light.jpg';
// import logo from './farmer.svg';
import logo from './r_black.jpg';

//import logo from './logo.svg';
import './App.css';

function App() {
  return (

    <div className="App">
      <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />

      <header className="App-header">
        <img src={logo} alt="logo" class="App-logo w3-left w3-circle w3-margin-right" />


        <p> Donate sats to the stack. </p>
        <a className="App-link" href="https://zbd.gg/rory" target="_blank" rel="noopener noreferrer">
          Donate Sats Here
        </a>
      </header>
    </div>
  );
}

export default App;
